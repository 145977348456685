import React from "react";
import injectSheet from "react-jss";

import faqData from "./faq-data";

const styles = theme => ({
  root: {
    background: theme.colors.bulletnBlue.main,
    padding: "30px 25px",
    "@global": {
      "p": {
        fontFamily: theme.typography.subHead,
        color: theme.colors.bulletnBlue.lighter,
      }
    }
  },
  faqsContainer: {
    maxWidth: "1000px",
    margin: "0 auto"
  },
  faq: {
    borderTop: `1px solid ${theme.colors.bulletnBlue.light}`,
    padding: "20px 0 0",
    [`@media (min-width: ${theme.breakpoints.medium}px)`]: {
      display: "grid",
      gridTemplateColumns: "30% 70%"
    }
  },
  h3: {
    fontSize: "1.6rem",
    fontWeight: "bold",
    margin: "0 0 10px 0",
    color: theme.colors.white,
    fontFamily: theme.typography.subHead,
    lineHeight: "2.6rem",
    [`@media (min-width: ${theme.breakpoints.medium}px)`]: {
      margin: "0 0 20px 0",
      maxWidth: "17.5em",
      paddingRight: "30px"
    },
  },
  h2: {
    textAlign: "center",
    fontSize: "2.4rem",
    marginBottom: "30px",
    color: theme.colors.white
  }
});

const FAQ = ({ classes }) => {
  return (
    <div className={classes.root}>
      <h2 className={classes.h2}>Frequently Asked Questions</h2>
      <div className={classes.faqsContainer}>
        {faqData.map(faq => (
          <div className={classes.faq}>
            <h3 className={classes.h3}>{faq.question}</h3>
            <div dangerouslySetInnerHTML={{ __html: faq.answer}} />
          </div>
        ))}
      </div>
    </div>
  )
};

export default injectSheet(styles)(FAQ);