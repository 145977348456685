import React from "react";
import injectSheet from "react-jss";

const styles = theme => ({
  container: {
    margin: "0 auto",
    padding: "0 15px",
    [`@media only screen and (min-width: ${theme.breakpoints.small}px)`]: {
      width: "750px"
    },
    [`@media only screen and (min-width: ${theme.breakpoints.medium}px)`]: {
      width: "970px"
    },
    [`@media only screen and (min-width: ${theme.breakpoints.large}px)`]: {
      width: "1170px"
    }
  }
});

const Container = props => {
  const { classes, className, children, ...otherProps } = props;

  return (
    <div className={`${classes.container} ${className}`} {...otherProps}>
      {children}
    </div>
  )
};

export default injectSheet(styles)(Container);