const styles = theme => ({
  button: {
    borderRadius: '2px',
    fontWeight: 'bold',
    fontFamily: theme.typography.body,
    fontSize: '14px',
    '&:focus': {
      outline: 'none',
    },
    '& svg': {
      marginRight: '5px',
      verticalAlign: 'middle',
    },
    '&:disabled': {
      background: theme.colors.gray.lighter,
      color: theme.colors.gray.dark,
    },
  },
  primary: {
    extend: 'button',
    background: theme.colors.blueGradient.main,
    color: theme.colors.white,
    border: 'none',
    '&:focus, &:hover': {
      color: theme.colors.white,
      background: theme.colors.blueGradient.light,
    },
    '&:active': {
      background: theme.colors.blueGradient.dark,
    },
  },
  secondary: {
    extend: 'button',
    background: theme.colors.white,
    color: theme.colors.bulletnBlue.main,
    border: `1px solid ${theme.colors.gray.main}`,
    '&:hover, &:focus': {
      color: theme.colors.bulletnBlue.main,
      borderColor: theme.colors.bulletnBlue.main,
    },
    '&:active': {
      borderColor: theme.colors.bulletnBlue.dark,
      background: theme.colors.gray.light,
    },
  },
  tertiary: {
    extend: 'button',
    background: theme.colors.white,
    color: theme.colors.steel.main,
    border: `1px solid ${theme.colors.gray}`,
    '&:hover, &:focus': {
      color: theme.colors.steel.main,
      borderColor: theme.colors.steel.main,
    },
    '&:active': {
      borderColor: theme.colors.steel.dark,
      color: theme.colors.steel.dark,
      background: theme.colors.gray.light,
    },
  },
  destructive: {
    extend: 'button',
    background: theme.colors.ruby.main,
    color: theme.colors.white,
    border: 'none',
    '&:hover, &:focus': {
      color: theme.colors.white,
      borderColor: theme.colors.ruby.light,
    },
    '&:active': {
      background: theme.colors.ruby.dark,
    },
  },
  link: {
    extend: 'button',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover, &:focus': {
      textDecoration: 'none',
    },
  },
  styleless: {
    background: 'none',
    border: 'none',
    lineHeight: '1',
    padding: '2px',
  },
  medium: {
    padding: '10px 35px',
  },
  mediumFixed: {
    height: '40px',
    width: '150px',
  },
  small: {
    padding: '7px 25px',
  },
  smallFixed: {
    height: '35px',
    width: '127px',
  },
  xsmall: {
    padding: '4px 15px',
    fontSize: '12px',
  },
  xsmallFixed: {
    height: '25px',
    width: '67px',
    fontSize: '12px',
  },
})

export default styles
