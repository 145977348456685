import React from 'react'
import PropTypes from 'prop-types'
import { Parallax } from 'react-scroll-parallax'
import injectSheet from 'react-jss'

const styles = () => ({
  overlay: {
    position: 'absolute',
  },
})

class ParallaxImg extends React.Component {
  constructor(props) {
    super(props)
    this.handleLoad = this.handleLoad.bind(this)
  }

  static contextTypes = {
    parallaxController: PropTypes.object.isRequired,
  }

  handleLoad() {
    this.context.parallaxController.update()
  }

  render() {
    const { imgClass, offsetYMax, offsetYMin, src, classes, overlay } = this.props

    return (
      <Parallax
        className={`${overlay ? classes.overlay : ''}`}
        offsetYMax={offsetYMax}
        offsetYMin={offsetYMin}
      >
        <img
          src={src}
          alt=""
          onLoad={this.handleLoad}
          className={imgClass}
        />
      </Parallax>
    )
  }
}

export default injectSheet(styles)(ParallaxImg)
