const faqData = [
  {
    question: "How much does this save over traditional printing?",
    answer: "<p>Every church’s printing expenses are different, but using a nationwide printing chain as a comparison, you can expect to save *at least 40%* over traditional brochure printing. Most price points save significantly more.</p>"
  },
  {
    question: "Should people be on their phones in church?",
    answer: "<p>This is a great question, with not a black and white answer. We’re not trying to encourage your congregation to be checking their fantasy football scores in the middle of the sermon. However, we believe that technology can be harnessed and redeemed for the good of the church. In decades past, hymnals gave way to overhead slides, which evolved into high-definition projectors to help your church follow along in music. Many read their Bibles on their smart devices. We simply want to leverage the amazing and growing technology that’s out there to help your church stay better connected and informed.</p>"
  },
  {
    question: "Many in my congregation don’t have smartphones, what should we do?",
    answer: "<p>As much as we want to help all churches, that means we probably aren’t the right tool for you! And that’s okay. We want churches to use whatever tools will best serve their unique congregations.</p>"
  },
  {
    question: "Do congregants need to pay to use the mobile app?",
    answer: "<p>Never! Bulletn will always be free for your church congregants to use.</p>"
  },
  {
    question: "Can I give it a try before I buy?",
    answer: "<p>Absolutely! We offer a free 30-day trial for you to test out our tool, and help you figure out how it would work for your church.</p>"
  },
  {
    question: "Can I also get a printed version of my bulletin?",
    answer: "<p>As of right now, no. Bulletn is a digital-exclusive platform, built primarily for churches that do not wish to print any bulletins as part of their connection and communication strategy. However, if you think that printing will still be necessary for your church, Bulletn still could be a great tool for you to add to reduce the amount that you spend on printing and labor!</p>"
  },
  {
    question: "What if we have record attendance one week and exceed our view limit?",
    answer: `<p>You hit a new record attendance, that’s awesome!</p><p>We want to celebrate with you! Don’t worry, we won’t automatically charge you for the next tier if you have 151 people for a week, and we will not lock out the 151st viewer from seeing your bulletin. We operate on a “loose limit” basis, and monitor churches’ usage over time. If we notice consistent and significant overusage, we may upgrade you to the next tier, but this won’t happen by an automatic trigger.</p>`
  }
];

export default faqData;