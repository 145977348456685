import React from 'react';
import injectSheet from 'react-jss';

import { Button } from '../../buttons';

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: theme.colors.white,
    boxShadow: `0px 5px 15px ${theme.colors.steelShadow.light}`,
    padding: "30px 25px",
    margin: "0 auto",
    width: "20rem"
  },
  h3: {
    fontSize: "1.5rem",
    marginBottom: "5px"
  },
  size: {
    fontSize: "1.6rem",
    color: theme.colors.steel.main,
    margin: "0 0 10px 0"
  },
  cost: {
    color: theme.colors.black,
    fontWeight: "bold",
    margin: "0 0 20px 0"
  }
});

const PlanCard = ({ plan, classes }) => {
  const { name, size, cost, Icon } = plan;

  return (
    <div className={classes.root}>
      <Icon height="128px"/>
      <h3 className={classes.h3}>{name}</h3>
      <p className={classes.size}>{size}</p>
      <p className={classes.cost}>{cost}</p>
      <Button
        href={`https://app.bulletn.co/signup`}
        btnSize="small"
      >
        Start free trial
      </Button>
    </div>
  )
};

export default injectSheet(styles)(PlanCard);