import React from 'react'
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'
import { Helmet } from 'react-helmet'
import injectSheet from 'react-jss'

import { IconBudget, IconCommunications, IconPeople } from '../components/icons'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import Features from '../components/landingPage/Features'
import Pricing from '../components/landingPage/Pricing'
import LandingPageHeader from '../components/landingPage/LandingPageHeader'
import AppStoreLinks from '../components/landingPage/AppStoreLinks'
import Attribute from '../components/landingPage/Attribute'
import LaunchingSoon from '../components/landingPage/LaunchingSoon'
import FAQ from '../components/landingPage/FAQ'
import Reviews from '../components/landingPage/Reviews'
import SpecialMessage from '../components/landingPage/SpecialMessage'

import styles from '../components/landingPage/styles.js'

// Offset all anchors by -60 to account for a fixed header
configureAnchors({ offset: -60 })

const IndexPage = props => (
  <div className={props.classes.root}>
    <Helmet>
      <title>Bulletn</title>
    </Helmet>
    <NavBar />
    <SpecialMessage />
    <LandingPageHeader />
    <AppStoreLinks />
    <Reviews />
    <ScrollableAnchor id={'why'}>
      <Attribute
        right
        icon={IconCommunications}
        title="Enhance your communications"
        body="The church bulletin has been a staple for decades, and is a valuable tool to get information to and from your congregation. However, printed bulletins are both functionally limiting and financially expensive."
        body2="With Bulletn, you can plan your bulletins months in advance, and focus on making authentic connections."
        thumb1="https://d2rdpmg2nfb82t.cloudfront.net/bulletn_1-1.png"
        thumb2="https://d2rdpmg2nfb82t.cloudfront.net/bulletn_1-2.png"
        thumb3="https://d2rdpmg2nfb82t.cloudfront.net/bulletn_1-3.png"
        thumbMobile="https://d2rdpmg2nfb82t.cloudfront.net/bulletn-landing-page-thumb-1.png"
      />
    </ScrollableAnchor>
    <Attribute
      icon={IconPeople}
      title="Reach your people"
      body="Smartphones have become an expected part of the Sunday morning experience in your congregation, and most people simply throw away unused printed bulletins anyway."
      body2="Connection cards in the Bulletn app allow your congregants to fill out prayer requests, express interest in joining a small group, indicate a desire for baptism, etc. This information is stored in your account’s database and can be easily searched and filtered so you don’t have to keep or sort through physical cards anymore."
      thumb1="https://d2rdpmg2nfb82t.cloudfront.net/bulletn_2-1.png"
      thumb2="https://d2rdpmg2nfb82t.cloudfront.net/bulletn_2-2.png"
      thumb3="https://d2rdpmg2nfb82t.cloudfront.net/bulletn_2-3.png"
      thumbMobile="https://d2rdpmg2nfb82t.cloudfront.net/bulletn-landing-page-thumb-2.png"
    />
    <Attribute
      right
      icon={IconBudget}
      title="Save your budget"
      body="Church resources are valuable, and printing is expensive. Instead of wasting money on paper, you can help another summer missions trip, replace the youth group carpets, support a new missionary overseas, or assist a family in need in your congregation."
      body2=""
      thumb1="https://d2rdpmg2nfb82t.cloudfront.net/bulletn_3-1.png"
      thumb2="https://d2rdpmg2nfb82t.cloudfront.net/bulletn_3-2.png"
      thumb3="https://d2rdpmg2nfb82t.cloudfront.net/bulletn_3-3.png"
      thumbMobile="https://d2rdpmg2nfb82t.cloudfront.net/bulletn-landing-page-thumb-3.png"
    />
    <ScrollableAnchor id={'features'}>
      <Features />
    </ScrollableAnchor>
    <ScrollableAnchor id={'pricing'}>
      <Pricing />
    </ScrollableAnchor>
    <ScrollableAnchor id={'faqs'}>
      <FAQ />
    </ScrollableAnchor>
    <ScrollableAnchor id={'launch'}>
      <LaunchingSoon />
    </ScrollableAnchor>
    <Footer />
  </div>
)

export default injectSheet(styles)(IndexPage)
