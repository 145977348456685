import React from 'react';
import injectSheet from 'react-jss';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Container from 'components/Container';
import ExplainerVideo from './ExplainerVideo';

const styles = theme => ({
  header: {
    position: 'relative',
    zIndex: 0,
    overflow: 'hidden',
    [`@media only screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      paddingTop: 0,
    },
  },
  headerText: {
    zIndex: 10,
    padding: '30px 15px 0',
    [`@media only screen and (min-width: ${theme.breakpoints.medium}px)`]: {
      paddingTop: '0',
    },
    [`@media only screen and (max-width: ${theme.breakpoints.small}px)`]: {
      textAlign: 'center',
    },
  },
  lineDivider: {
    width: '75px',
    height: '4px',
    background: theme.colors.bulletnBlue.main,
    margin: '30px 0',
    [`@media only screen and (max-width: ${theme.breakpoints.small}px)`]: {
      margin: '30px auto',
    },
  },
  headerMocksMobile: {
    position: 'relative',
  },
  desktop: {
    display: 'inline-block',
    [`@media only screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    [`@media only screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      display: 'block',
    },
  },
  headerImage: {
    [`@media only screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      display: 'none',
    },
  },
  headerMocksFixed: {
    width: '100%',
    padding: '10px',
    marginTop: '25px',
  },
  headerBlueBackground: {
    position: 'relative',
    height: '1000px',
    zIndex: -100,
    left: '67%',
    marginTop: '-900px',
    background: `linear-gradient(
      135deg,
      ${theme.colors.bulletnBlue.light} 0%,
      ${theme.colors.bulletnBlue.main} 100%
    )`,
    display: 'none',
    [`@media only screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      height: '250px',
      margin: '-200px -15px 0px',
      left: 0,
      top: 0,
      display: 'block',
    },
    [`@media only screen and (max-width: ${theme.breakpoints.small}px)`]: {
      height: '150px',
      marginTop: '-125px',
      display: 'block',
    },
  },
  headerContentContainer: {
    [`@media only screen and (min-width: ${theme.breakpoints.medium}px)`]: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate( -50%, -50%)',  
    },
  },
})

const LandingPageHeader = props => {
  const { classes } = props

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "landing-header.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
      <header className={classes.header}>
        <Img fluid={data.file.childImageSharp.fluid} className={classes.headerImage}/>
        <Container className={classes.headerContentContainer}>
          <div className={classes.headerText}>
            <h1>Stop wasting paper on Sunday morning.</h1>
            <div className={classes.lineDivider} />
            <p>
              Church bulletins are a valuable resource, but constant printing
              can be a waste to your time and budget, not to mention the
              environment. Welcome to Bulletn, your newer and better Sunday
              morning communication software.
            </p>
            <ExplainerVideo />
          </div>
          <div className={`${classes.headerMocksMobile} ${classes.mobile}`}>
            <img
              src="https://d2rdpmg2nfb82t.cloudfront.net/bulletn-mocks-fixed.png"
              alt="Bulletn Mockups"
              className={classes.headerMocksFixed}
            />
          </div>
        </Container>
        <div className={classes.headerBlueBackground}/>
      </header>
  )
}

export default injectSheet(styles)(LandingPageHeader)
