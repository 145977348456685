import React from 'react';
import injectSheet from 'react-jss';

const styles = () => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '3px'
  }
});

const Loading = ({ alt, classes }) => {
  return (
    <div className={classes.root}>
      <img
        src="https://d2rdpmg2nfb82t.cloudfront.net/bulletn-monogram.gif"
        alt={alt || 'Loading'}
        width="75"
      />
    </div>
  );
};

export default injectSheet(styles)(Loading);