import React from "react";
import injectSheet from "react-jss";
import {
  IconChurchPlant,
  IconSmallChurch,
  IconLargeChurch
} from '../../icons';

import PlanCard from "./PlanCard";

const plans = [
  { name: "Plant", size: "50 people/wk", cost: "$9.99/mo", Icon: IconChurchPlant },
  { name: "Small", size: "200 people/wk", cost: "$29.99/mo", Icon: IconSmallChurch },
  { name: "Large", size: "200+ people/wk", cost: "$49.99/mo", Icon: IconLargeChurch }
];

const styles = theme => ({
  root: {
    padding: "30px 0",
    [`@media (min-width: ${theme.breakpoints.small}px)`]: {
      background: theme.colors.blueBg,
      padding: "50px 15px"
    },
  },
  h2: {
    fontSize: "2.2rem",
    fontWeight: "bold",
    color: theme.colors.gray.darker,
    textAlign: "center"
  },
  p: {
    margin: "20px auto 0",
    padding: "0 25px",
    textAlign: "center",
    fontFamily: theme.typography.subHead,
    [`@media (min-width: ${theme.breakpoints.small}px)`]: {
      padding: 0,
      width: "80%",
      maxWidth: "40em"
    },
  },
  plansOuter: {
    overflowX: "auto",
    marginTop: "20px"
  },
  plans: {
    display: "flex",
    width: "74rem",
    justifyContent: "space-between",
    padding: "20px 40px",
    margin: "0 auto"
  }
});

const Pricing = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
        <h2 className={classes.h2}>Pricing plans</h2> 
        <p className={classes.p}>No matter your church size, we’re confident that you’ll come out ahead over traditional printed church bulletins. To make it even easier, we've extended our 30-day free trial to a 90-day trial in an effort to help churches that are responding to COVID-19.</p>
        <div className={classes.plansOuter}>
          <div className={classes.plans}>
            {plans.map(plan => <PlanCard plan={plan} />)}
          </div>
        </div>
    </div>
  );
};

export default injectSheet(styles)(Pricing);