import React from "react";
import injectSheet from "react-jss";
import FeatureDetail from "./FeatureDetail";
import featuresData from "./features-data";

const styles = theme => ({
  root: {
    background: theme.colors.blueBg,
    padding: "10px 0",
    [`@media (min-width: ${theme.breakpoints.small}px)`]: {
      padding: "50px 15px"
    },
  },
  card: {
    background: theme.colors.white,
    boxShadow: `0px 5px 15px ${theme.colors.steelShadow.light}`,
    padding: "25px",
    margin: "0 auto",
    [`@media (min-width: ${theme.breakpoints.small}px)`]: {
      padding: "40px",
      maxWidth: "100%",
      width: "45em"
    },
    [`@media (min-width: 1100px)`]: {
      width: "65em"
    },
  },
  h2: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: theme.colors.gray.darker,
    textAlign: "center"
  },
  p: {
    margin: "20px auto 0",
    textAlign: "center",
    fontFamily: theme.typography.subHead,
    [`@media (min-width: 1100px)`]: {
      width: "75%",
      maxWidth: "40em"
    },
  },
  features: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "20px",
    [`@media (min-width: 1100px)`]: {
      justifyContent: "left",
      margin: "20px auto 0",
      width: "57em"
    },
  }
});

const Features = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <h2 className={classes.h2}>Everything you need for easy, amazing bulletins</h2> 
        <p className={classes.p}>Bulletn is designed to help you to easily create beautiful bulletins every week. Every subscription includes these amazing features:</p>
        <div className={classes.features}>
          {featuresData.map(feature => (
            <FeatureDetail {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default injectSheet(styles)(Features);