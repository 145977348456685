import React from 'react'
import injectSheet from 'react-jss'

import { Button } from '../../buttons'

const styles = theme => ({
  navList: {
    listStyleType: 'none',
    display: 'flex',
    margin: 0,
    padding: 0,
    alignItems: 'center'
  },
  actionButtons: {
    display: 'flex',
  },
  why: {
    color: theme.colors.steel.main,
    fontWeight: 'bold',
    fontSize: '1.5rem',
    padding: '10px 20px',
    textAlign: 'center',
    '&:hover, &:focus': {
      background: theme.colors.gray.lighter,
      textDecoration: 'none'
    },
  },
  mobile: {
    lineHeight: 1,
    '& $navList': {
      flexDirection: 'column',
      width: '100%',
      '@global': {
        'li + li, a + a': {
          margin: '15px 0 0 0',
        },
        'button, a, li': {
          width: '100%',
        },
        a: {
          display: 'block',
          padding: "15px 0"
        }
      },
    },
    '& $actionButtons': {
      flexDirection: 'column',
      '@global': {
        a: {
          margin: '15px 0 0 0',
          padding: "15px 0"
        },
      },
    }
  },
  desktop: {
    display: 'none',
    [`@media (min-width: ${theme.breakpoints.medium}px)`]: {
      flex: '1 0 0',
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: "20px",
    },
    '& $actionButtons': {
      '@global': {
        'a + a': {
          margin: '0 0 0 15px',
        },
      },
    },
    '& $navList': {
      '@global': {
        'li + li': {
          margin: '0 0 0 15px',
        },
      },
    }
  }
})

const NavLinks = props => {
  const { classes, mobile, isMobileNavVisible, toggleMobileNav } = props

  return (
    <div className={mobile ? classes.mobile : classes.desktop}>
      <nav>
        <ul className={classes.navList}>
          <li>
            <a
              href="#features"
              onClick={isMobileNavVisible ? toggleMobileNav : undefined}
              className={classes.why}
            >
              Features
            </a>
          </li>
          <li>
            <a
              href="#pricing"
              onClick={isMobileNavVisible ? toggleMobileNav : undefined}
              className={classes.why}
            >
              Pricing
            </a>
          </li>
          <li>
            <a
              href="#faqs"
              onClick={isMobileNavVisible ? toggleMobileNav : undefined}
              className={classes.why}
            >
              FAQ
            </a>
          </li>
          <li>
            <a
              href="mailto:hello@bulletn.co"
              className={classes.why}
            >
              Contact Us
            </a>
          </li>
        </ul>
      </nav>
      <div className={classes.actionButtons}>
        <Button
          href={`https://app.bulletn.co/login`}
          btnSize={mobile ? 'medium' : 'smallFixed'}
          btnStyle="secondary"
        >
          Log in
        </Button>
        <Button
          href={`https://app.bulletn.co/signup`}
          btnSize={mobile ? 'medium' : 'smallFixed'}
          onClick={isMobileNavVisible ? toggleMobileNav : undefined}
        >
          90 Days Free
        </Button>
      </div>
    </div>
  )
}

NavLinks.defaultProps = {
  mobile: true,
}

export default injectSheet(styles)(NavLinks)
