import React from 'react';
import injectSheet, { withTheme } from 'react-jss';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { IconBlockquote } from '../../icons';

const styles = theme => ({
  root: {
    display: 'flex',
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '50px 10% 40px',
    [`@media only screen and (min-width: ${theme.breakpoints.small}px)`]: {
      padding: '100px 75px 30px',
    }
  },
  pastorDetailsContainer: {
    display: "flex",
    alignItems: 'center',
    [`@media only screen and (min-width: ${theme.breakpoints.small}px)`]: {
      display: 'block'
    }
  },
  pastorImage1: {
    display: 'none',
    [`@media only screen and (min-width: ${theme.breakpoints.small}px)`]: {
      width: '32%',
      display: 'block'
    }
  },
  pastorImage2: {
    paddingRight: '20px',
    minWidth: '125px',
    width: '30%',
    [`@media only screen and (min-width: ${theme.breakpoints.small}px)`]: {
      display: 'none'
    }
  },
  reviewDetails: {
    [`@media only screen and (min-width: ${theme.breakpoints.small}px)`]: {
      width: '68%',
      paddingLeft: '75px'
    },
  },
  reviewText: {
    padding: "20px 0 5px"
  },
  pastorName: {
    fontWeight: "bold",
    color: theme.colors.gray.darker
  }
});

const Reviews = ({ classes, theme }) => {
  const { churchLogo, pastorImage } = useStaticQuery(graphql`
    query {
      churchLogo: file(relativePath: { eq: "the-mission-logo@2x.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      pastorImage: file(relativePath: { eq: "ritch-headshot@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 255) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <div className={classes.root}>
      <div className={classes.pastorImage1}>
        <Img fluid={pastorImage.childImageSharp.fluid} />
      </div>
      <div className={classes.reviewDetails}>
        <IconBlockquote fill={theme.colors.gray.darker} />
        <p className={classes.reviewText}>Bulletn has everything we need to communicate effectively to our congregation without all the clutter and cost of other options. We can easily make changes on the fly, and know that the families at our church are getting the best and most up-to-date information, at a fraction of the cost of and work of printing or other apps!</p>
        <div className={classes.pastorDetailsContainer}>
          <div className={classes.pastorImage2}>
            <Img fluid={pastorImage.childImageSharp.fluid} />
          </div>
          <div>
            <p><span className={classes.pastorName}>Ritch Sanford</span>, Pastor at The Mission Church</p>
            <Img fixed={churchLogo.childImageSharp.fixed} />
          </div>
        </div>
      </div>
    </div>
  )
};

export default injectSheet(styles)(withTheme(Reviews));