import React from 'react'
import injectSheet from 'react-jss'

import styles from './ButtonGroup.styles.js'

/**
 * Button Component
 * @param {*} props
 *  float:
 */
const ButtonGroup = props => {
  return <div className={props.classes.buttonGroup}>{props.children}</div>
}

export default injectSheet(styles)(ButtonGroup)
