import React, { Fragment, useState } from 'react';
import injectSheet from 'react-jss';
import Fade from 'react-reveal/Fade';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import '@reach/dialog/styles.css';

import { Button } from 'components/buttons';
import Loading from 'components/Loading';

const styles = theme => ({
  dialogOverlay: {
    background: theme.colors.dialogBg,
    display: 'flex',
    alignItems: 'center',
    padding: '15px'
  },
  dialogContent: {
    width: '100%',
    maxWidth: '616px',
    maxHeight: '346.5px',
    height: 'calc((100vw - 30px) * 315 / 560)',
    padding: 0,
    [`@media only screen and (min-width: ${theme.breakpoints.medium}px)`]: {
      maxWidth: '840px',
      maxHeight: '472.5px',
    },
  },
  iframe: {
    width: '100%',
    height: '100%'
  }
});

const ExplainerVideo = ({ classes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const handleVideoLoad = () => setIsVideoLoaded(true);

  return (
    <Fragment>
      <Button btnSize="medium" onClick={open}>See how it works</Button>
      <DialogOverlay
        className={classes.dialogOverlay}
        isOpen={isOpen}
        onDismiss={close}
      >
        <DialogContent className={classes.dialogContent}>
          {!isVideoLoaded && <Loading alt="Loading explainer video" />}
          <Fade when={isVideoLoaded}>
            <iframe
              onLoad={handleVideoLoad}
              className={classes.iframe}
              src="https://www.youtube-nocookie.com/embed/BsuiMKGLMkc"
              title="Bulletn Explainer Video"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Fade>
        </DialogContent>
      </DialogOverlay>
    </Fragment>
  );
};

export default injectSheet(styles)(ExplainerVideo);