import React from 'react'
import ReactDOM from 'react-dom'
import injectSheet from 'react-jss'
import { IconX } from "bulletn-component-library/icons";

import NavLinks from '../NavLinks'
import { Button } from '../../buttons'

const styles = theme => ({
  root: {
    [`@media (min-width: ${theme.breakpoints.medium}px)`]: {
      display: 'none',
    },
  },
  menu: {
    position: 'fixed',
    zIndex: theme.zIndex.bmContainer,
    top: 0,
    bottom: 0,
    right: '-20em',
    background: 'white',
    padding: '60px 20px 20px',
    width: '20em',
    transition: "right .5s ease"
  },
  menuShow: {
    right: 0
  },
  overlay: {
    display: "none",
    zIndex: theme.zIndex.bmOverlay,
    background: theme.colors.steelShadow.dark,
    position: "fixed",
    top: 0,
    bottom: 0,
    opacity: 0,
    width: "100%"
  },
  overlayShow: {
    display: "block",
    transition: "opacity .5s ease"
  },
  overlayAnimate: {
    opacity: 1
  },
  burger: {
    position: "relative",
    background: "none",
    border: "none",
    padding: 0,
    "&:before": {
      content: '""',
      display: "block",
      height: "20px",
      width: "25px",
      background: `linear-gradient(
        to bottom, 
        black, black 8%, 
        transparent 8%, transparent 46%, 
        black 47%, black 53%, 
        transparent 54%, transparent 92%, 
        black 92%, black 100%
      )`
    }
  },
  close: {
    position: "absolute",
    right: "20px",
    top: "20px",
  }
})

class MobileNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false
    };
    this.menu = React.createRef();
    this.overlay = React.createRef();
  }

  componentDidMount() {
    // Issue seems to be a mismatch between what the server renders and what the browser renders
    // Apparently, if there's a mismatch between the initial render of the server and the initial
    // render of the browser, odd issues can happen. Using Portals causes issues here, so now
    // just render the portal on mount
    this.setState({
      isMounted: true
    });
  }

  componentDidUpdate() {
    const { isMobileNavVisible } = this.props;
    const { menu, menuShow, overlay, overlayShow, overlayAnimate } = this.props.classes;
    if (!this.overlay.current) {
      return;
    }
    if (isMobileNavVisible) {
      // Set body overflow hidden to get rid of scrolling when mobile menu is open
      document.getElementsByTagName('body')[0].setAttribute('style', 'overflow: hidden')
      this.overlay.current.className = `${overlay} ${overlayShow}`;
      setTimeout(() => {
        if (!this.overlay.current) {
          return;
        }
        this.overlay.current.className = `${overlay} ${overlayShow} ${overlayAnimate}`;
      });
      this.menu.current.className = `${menu} ${menuShow}`;
    } else {
      document.getElementsByTagName('body')[0].setAttribute('style', '')
      this.overlay.current.className = `${overlay} ${overlayShow}`;
      setTimeout(() => {
        if (!this.overlay.current) {
          return;
        }
        this.overlay.current.className = `${overlay}`;
      }, 500);
      this.menu.current.className = `${menu}`;
    }
  }

  render() {
    const { classes, isMobileNavVisible, toggleMobileNav } = this.props;

    if (!this.state.isMounted) {
      return null;
    }

    return (
      <div className={classes.root}>
        <button onClick={toggleMobileNav} className={classes.burger}/>
        {ReactDOM.createPortal(
          <React.Fragment>
            <div ref={this.overlay} className={classes.overlay} onClick={toggleMobileNav}></div>
            <div ref={this.menu} className={classes.menu}>
              <Button btnStyle="styleless" className={classes.close} onClick={toggleMobileNav} aria-label="Close menu">
                <IconX width="20" />
              </Button>
              <NavLinks toggleMobileNav={toggleMobileNav} isMobileNavVisible={isMobileNavVisible} />
            </div>
          </React.Fragment>,
          document.getElementById('menu')
        )}
      </div>
    )
  }
}

export default injectSheet(styles)(MobileNav)
