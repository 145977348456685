import {
  IconCalendar,
  IconEdit,
  IconPlanned,
  IconCamera,
  IconLink,
  IconMultiple,
  IconResponses,
  IconTemplate,
  IconInternet
} from "../../icons";

const features = [
  {
    header: "Plan ahead",
    icon: IconCalendar,
    description: "Plan your bulletins for weeks or months ahead, and have them automatically update every week in time for your service"
  },
  {
    header: "Easy to design",
    icon: IconPlanned,
    description: "Our bulletin editor gives you all the flexibility you need, without any of the distractions."
  },
  {
    header: "Use your custom graphics",
    icon: IconCamera,
    description: "Make each bulletin feel like your own! Seemlessly incorporate your custom graphics into your weekly bulletin."
  },
  {
    header: "External links",
    icon: IconLink,
    viewBox: "-5 0 17 24",
    description: "Give your congregation instant access to important content such as event signups, sermon audio, etc. all in one place."
  },
  {
    header: "Web link",
    icon: IconInternet,
    description: "Create a web link to share your bulletin anywhere you'd like, and make it viewable outside the Bulletn app."
  },
  {
    header: "Response cards",
    comingSoon: true,
    icon: IconResponses,
    description: "No more collecting paper and reading chicken scratch. Have your congregants fill out easily sortable response cards!"
  },
  {
    header: "Sermon notes",
    comingSoon: true,
    icon: IconEdit,
    description: "Congregants can continue taking sermon notes, and send an email to themselves each week!"
  },
  {
    header: "Multiple services",
    comingSoon: true,
    icon: IconMultiple,
    description: "Plan multiple services at once, to give each service it's own look and feel."
  },
  {
    header: "Templates",
    comingSoon: true,
    icon: IconTemplate,
    description: "Create template snippets to make creating bulletins even easier and faster."
  }
];

export default features;