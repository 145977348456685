import React, { useState, useEffect } from 'react';
import injectSheet from 'react-jss';
import Fade from 'react-reveal/Fade'

import { Button } from '../../buttons';
import { IconX } from '../../icons';
import Container from '../../Container'

const styles = theme => ({
  root: {
    position: 'relative',
    width: '100%',
    background: theme.colors.bulletnBlue.main,
    textAlign: 'center',
    padding: '40px 0 10px',
    [`@media only screen and (min-width: ${theme.breakpoints.small}px)`]: {
      padding: '30px 0',
    }
  },
  container: {
    position: "relative"
  },
  line1: {
    color: theme.colors.white,
    lineHeight: 1
  },
  line2: {
    color: theme.colors.white,
    lineHeight: 1,
    fontSize: '1.5rem',
    opacity: .85
  },
  dismiss: {
    position: 'absolute',
    right: '15px',
    top: '-25px',
    [`@media only screen and (min-width: ${theme.breakpoints.small}px)`]: {
      right: '30px',
      top: '50%',
      transform: 'translateY(-50%)',
    }
  }
});

const SpecialMessage = ({ classes }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeMessage = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(true);
  }, [])

  return isOpen && (
    <Fade top>
      <div className={classes.root}>
        <Container className={classes.container}>
          <p className={classes.line1}>
            First Three Months Free to Help During COVID-19
          </p>
          <p className={classes.line2}>
            No need for a promo code, your card won't be charged. We're here to help.
          </p>
          <Button
            btnStyle="styleless"
            className={classes.dismiss}
            aria-label="close"
            onClick={closeMessage}
          >
            <IconX fill="#fff" height={18} width={18} />
          </Button>
        </Container>
      </div>
    </Fade>
  );
};

export default injectSheet(styles)(SpecialMessage);
