import React from 'react'
import injectSheet from 'react-jss'
import { isIOS } from 'react-device-detect'
import Container from '../../Container'

const styles = theme => ({
  appStoreLink: {
    display: 'inline-block',
    overflow: 'hidden',
    background:
      'url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2018-11-03&kind=iossoftware&bubble=ios_apps) no-repeat',
    width: '135px',
    height: '40px',
    margin: '0px 15px 0 10px',
  },
  playStoreLink: {
    width: '160px',
  },
  componentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '20px',
    borderBottom: '1px solid',
    borderBottomColor: `${theme.colors.steel.lighter}`,
    margin: '0',
    width: '100%',
    [`@media only screen and (max-width: ${theme.breakpoints.small}px)`]: {
      flexDirection: 'column'
    },
    [`@media only screen and (max-width: ${theme.breakpoints.medium}px)`]: {
      padding: '30px 0 30px 0'
    },
  },
  linksContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  downloadText: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    margin: "0 20px 0 0",
    [`@media only screen and (max-width: ${theme.breakpoints.small}px)`]: {
      margin: '20px 0',
    },
  },
  hiddenContent: {
    display: 'none',
  },
})

const AppStoreLinks = ({ classes }) => {
  return (
    <Container className={classes.componentContainer}>
      <h3 className={classes.downloadText}>Download the mobile app</h3>
      <div className={classes.linksContainer}>
        <a
          href="https://itunes.apple.com/us/app/bulletn-co/id1439793906?mt=8"
          className={classes.appStoreLink}
        >
          <p className={classes.hiddenContent}>Download from the App Store</p>
        </a>
        {isIOS ? null : (
          <a href="https://play.google.com/store/apps/details?id=co.bulletn.mobile&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
              className={classes.playStoreLink}
            />
          </a>
        )}
      </div>
    </Container>
  )
}

export default injectSheet(styles)(AppStoreLinks)
