import React from "react";
import injectSheet from "react-jss";

const styles = theme => ({
  root: {
    width: "19em",
    padding: "15px 10px 15px 20px",
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    "@global": {
      "svg *": {
        fill: `${theme.colors.bulletnBlue.main} !important`
      }
    }
  },
  comingSoon: {
    marginLeft: "15px",
    borderRadius: "10px",
    color: theme.colors.steel.main,
    padding: "3px 15px",
    fontSize: ".8rem",
    background: theme.colors.steel.lighter,
    textTransform: "uppercase",
    fontWeight: "bold",
    fontFamily: theme.typography.heading
  },
  h3: {
    fontSize: "1.6rem",
    color: theme.colors.gray.darker,
    margin: "0 0 15px 10px",
    fontFamily: theme.typography.subHead,
    fontWeight: 700
  },
  p: {
    paddingLeft: "30px",
    fontSize: "1.5rem"
  }
});

const FeatureDetail = props => {
  const { classes, icon, header, description, comingSoon, viewBox } = props;
  const Icon = icon;
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Icon width={17} viewBox={viewBox} />
        <h3 className={classes.h3}>{header}</h3>
        {comingSoon && <div className={classes.comingSoon}>Coming Soon</div>}
      </div>
      <p className={classes.p}>{description}</p>
    </div>
  );
};

FeatureDetail.defaultProps = {
  commingSoon: false
};

export default injectSheet(styles)(FeatureDetail);