import React from 'react'
import injectSheet from 'react-jss'

import styles from './Button.styles.js'

/**
 * Button Component
 * @param {*} props
 *
 *  btnSize: xsmall, xsmallFixed, small, smallFixed, medium, mediumFixed (default: medium)
 *  btnStyle: styleless, primary, secondary, tertiary, destructive (default: primary)
 */
const Button = props => {
  let {
    btnSize,
    btnStyle,
    className,
    refFn,
    classes,
    theme,
    href,
    ...otherProps
  } = props

  if (href) {
    return (
      <a
        className={`${classes.link} ${classes[btnStyle]} ${
          classes[btnSize]
        } ${className}`}
        href={href}
        {...otherProps}
      >
        {props.children}
      </a>
    )
  }

  return (
    <button
      className={`${classes[btnStyle]} ${className} ${classes[btnSize]}`}
      ref={refFn}
      {...otherProps}
    >
      {props.children}
    </button>
  )
}

Button.defaultProps = {
  btnStyle: 'primary',
  btnSize: '', // by default: no size, no padding
  className: '',
}

export default injectSheet(styles)(Button)
