const styles = theme => ({
  containerOuter: {
    background: theme.colors.white,
    boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.05)',
  },
  navBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 15px',
    height: '60px',
    [`@media (min-width: ${theme.breakpoints.medium}px)`]: { 
      padding: '0px 15px',
      justifyContent: 'normal'
    },
  },
  logo: {
    '@global': {
      img: {
        height: '2.5em',
      },
    },
  }
})

export default styles
