import React from 'react'
import injectSheet from 'react-jss'
import Fade from 'react-reveal/Fade'
import { ParallaxProvider } from 'react-scroll-parallax'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import ParallaxImg from '../../ParallaxImg'
import Container from '../../Container'

const styles = theme => {
  return {
    root: {
      overflow: 'hidden',
      '&:nth-child(even)': {
        backgroundColor: theme.colors.gray.lightest,
      },
    },
    inner: {
      maxWidth: '1170px',
      margin: '0 auto',
      padding: '50px 10%',
      [`@media (min-width: ${theme.breakpoints.medium}px)`]: {
        padding: '50px 30px',
      },
      [`@media (min-width: ${theme.breakpoints.medium}px)`]: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: '100px 0',
      },
    },
    description: {
      [`@media (min-width: ${theme.breakpoints.small}px)`]: {
        padding: '0 10% 0',
      },
      [`@media (min-width: ${theme.breakpoints.medium}px)`]: {
        padding: '0 7%',
      },
      [`@media (min-width: ${theme.breakpoints.large}px)`]: {
        padding: '0 5%',
      }
    },
    dotBackground: {
      backgroundPosition: 'bottom right',
      backgroundSize: '15%',
      height: 'auto',
    },
    imageContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      [`@media (min-width: ${theme.breakpoints.medium}px)`]: {
        width: '50%',
      },
    },
    right: {
      [`@media (min-width: ${theme.breakpoints.medium}px)`]: {
        flexDirection: 'row-reverse',
      },
      '& $imageContainer': {
        justifyContent: 'flex-end',
      },
      '& $description': {
        [`@media (min-width: ${theme.breakpoints.small}px)`]: {
          padding: '0 0 0 10%',
        },
      },
    },
    image: {
      width: '100%',
      [`@media (min-width: ${theme.breakpoints.medium}px)`]: {
        width: '49em',
      },
    },
    a: {
      fontFamily: theme.typography.heading,
      fontWeight: 700,
      lineHeight: '22px',
      color: theme.colors.bulletnBlue.main,
      paddingBottom: '25px',
      transition: '.2s',
      display: 'inline-block',
      '&:hover, &:visited, &:focus, &:active': {
        textDecoration: 'none',
      },
      '&:hover, &:hover:visited': {
        color: theme.colors.jade.main,
      },
      '&:visited': {
        color: theme.colors.bulletnBlue.main,
      },
    },
    h2: {
      fontSize: '2em',
      color: theme.colors.black,
      [`@media (min-width: ${theme.breakpoints.small}px)`]: {
        fontSize: '2.2em',
      },
      [`@media (min-width: ${theme.breakpoints.large}px)`]: {
        fontSize: '2.8em',
      },
    },
  }
}

class Attribute extends React.Component {
  constructor() {
    super()
    this.state = {
      width: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
    this.setState({
      width: window.innerWidth,
    })
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const {
      classes,
      id,
      title,
      body,
      body2,
      thumb1,
      thumb2,
      thumb3,
      thumbMobile,
      right,
    } = this.props

    const Icon = this.props.icon
    const { width } = this.state
    const isMobile = width <= 992

    return (
      <StaticQuery
        query={graphql`
          query {
            file(relativePath: { eq: "dot-texture.png" }) {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        `}
        render={data => {
          const imageData = data.file.childImageSharp.fluid
          return (
            <div id={id} className={classes.root}>
              <Container
                className={`${classes.inner} ${right ? classes.right : ''}`}
              >
                <BackgroundImage fixed={imageData} className={classes.dotBackground}>
                  <div className={classes.description}>
                    <Icon />
                    <h2 className={classes.h2}>{title}</h2>
                    <p>{body}</p>
                    <p>{body2}</p>
                    <a className={classes.a} href="https://app.bulletn.co/signup">
                      Sign up ⟶
                    </a>
                  </div>
                </BackgroundImage>
                {isMobile ? (
                  <Fade bottom>
                    <img
                      className={`${classes.image}`}
                      src={thumbMobile}
                      alt=""
                    />
                  </Fade>
                ) : (
                  <div className={`${classes.imageContainer}`}>
                    <ParallaxProvider>
                      <ParallaxImg
                        imgClass={`${classes.image}`}
                        src={thumb1}
                        key={0}
                        offsetYMax={0}
                        offsetYMin={0}
                      />
                      <ParallaxImg
                        imgClass={`${classes.image}`}
                        src={thumb2}
                        key={1}
                        offsetYMax={'100px'}
                        offsetYMin={'-100px'}
                        overlay
                      />
                      <ParallaxImg
                        imgClass={`${classes.image}`}
                        src={thumb3}
                        key={2}
                        offsetYMax={'300px'}
                        offsetYMin={'-300px'}
                        overlay
                      />
                    </ParallaxProvider>
                  </div>
                )}
              </Container>
            </div>
          )
        }}
      />
    )
  }
}

export default injectSheet(styles)(Attribute)
