const styles = theme => ({
  root: {
    background: theme.colors.white
  },
  "@global": {
    "h1, h2, h3": {
      fontFamily: theme.typography.heading,
      lineHeight: "1.1em",
      fontWeight: 600
    },
    h1: {
      fontSize: "3.9em",
      lineHeight: "1.1em",
      letterSpacing: "-2px",
      marginTop: 0,
      color: theme.colors.black
    },
    p: {
      fontFamily: theme.typography.body,
      fontSize: "1em",
      fontWeight: 400,
      lineHeight: "1.5em",
      color: theme.colors.steel.main,
      marginBottom: "20px",
    },
    body: {
      fontSize: "16px"
    },
    input: {
      fontFamily: theme.typography.body
    },
    [`@media only screen and (max-width: 1200px)`]: {
      h1: {
        fontSize: "3.2em"
      }
    },
    [`@media only screen and (max-width: 992px)`]: {
      h1: {
        fontSize: "3em"
      }
    },
    [`@media only screen and (max-width: 768px)`]: {
      h1: {
        fontSize: "2.75em"
      }
    }
  }
});

export default styles;  