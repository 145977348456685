import React from 'react'
// import './signupForm.css'
import injectSheet from "react-jss";

const styles = theme => {
  return {
    root: {
      fontFamily: theme.typography.heading,
      marginTop: "30px",
    },
    inline: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& $button": {
        margin: "30px 0 0 0"
      },
      "& $email": {
        textAlign: "center"
      },
      [`@media (min-width: ${theme.breakpoints.small}px)`]: {
        "& [type=email]": {
          textAlign: "left",
        },
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        "& [type=submit]": {
          margin: "0 0 0 20px"
        }
      }
    },
    stacked: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& $button": {
        marginTop: "20px"
      }
    },
    email: {
      width: "100%",
      maxWidth: "22em",
      padding: "13px",
      borderRadius: "2px",
      border: "none",
      fontWeight: 700,
      "&::placeholder": {
        fontWeight: 500
      },
      "&:focus::placeholder": {
        opacity: 0.25,
        transition: "opacity 0.35s ease-in-out;"
      },
      "&:focus": {
        outline: "none"
      }
    },
    button: {
      padding: "13px 35px",
      borderRadius: "3px",
      border: "none",
      transition: "200ms",
      fontWeight: "bold",
      "&:hover": {
        transform: "scale(1.02)",
      }
    }
  }
}
class SignupForm extends React.Component {
  render() {
    const { classes, align, emailClass, buttonClass } = this.props;
    return (
      <form
        action="https://bulletn.us18.list-manage.com/subscribe/post?u=04423e457bcc0ff76dca83063&id=b917455cc7"
        method="post"
        className={`${classes.root} ${classes[align]}`}
        target="_blank"
        noValidate
      >
        <input
          type="email"
          name="EMAIL"
          className={`${classes.email} ${emailClass}`}
          id="mce-EMAIL"
          placeholder="your@email.com"
          onChange={this.handleChange}
          required
        />
        <input
          type="submit"
          value="Sign me up"
          name="subscribe"
          id="mc-embedded-subscribe"
          className={`${classes.button} ${buttonClass}`}
        />
      </form>
    )
  }
}

SignupForm.defaultProps = {
  align: "inline",
  emailClass: "",
  buttonClass: ""
};

export default injectSheet(styles)(SignupForm);
