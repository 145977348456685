import React from 'react'
import injectSheet from "react-jss";

const styles = theme => ({
  footer: {
    width: "100%",
    height: "75px",
    display: "block",
    backgroundColor: theme.colors.bulletnBlue.main
  }
});

class Footer extends React.Component {
  render() {
    const { classes } = this.props;
    return <div className={classes.footer} />
  }
}

export default injectSheet(styles)(Footer);
