import React, { Component } from 'react'
import injectSheet from 'react-jss'

import Container from "../Container";
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'

import styles from './NavBar.styles.js'

export class NavBar extends Component {
  constructor() {
    super();
    this.toggleMobileNav = this.toggleMobileNav.bind(this);
    this.state = {
      isMobileNavVisible: false
    }
  }

  toggleMobileNav() {
    this.setState({
      isMobileNavVisible: !this.state.isMobileNavVisible,
    })
  }

  render() {
    const { classes } = this.props;
    const { isMobileNavVisible } = this.state;

    return (
      <div className={classes.containerOuter}>
        <Container>
          <div className={classes.navBar}>
            <a href="#home" className={classes.logo}>
              <img
                src="https://d2rdpmg2nfb82t.cloudfront.net/Bulletn-Logo.png"
                alt="Bulletn"
              />
            </a>
            <MobileNav toggleMobileNav={this.toggleMobileNav} isMobileNavVisible={isMobileNavVisible} />
            <DesktopNav />
          </div>
        </Container>
      </div>
    )
  }
}

export default injectSheet(styles)(NavBar)
