import React from 'react'
import Fade from 'react-reveal/Fade'
import SignupForm from '../SignupForm'
import injectSheet from 'react-jss'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    background: `linear-gradient(
      135deg,
      ${theme.colors.bulletnBlue.light} 0%,
      ${theme.colors.bulletnBlue.main} 100%
    )`,
    padding: '40px 25px 70px 25px',
    textAlign: 'center',
    '& h2, & p': {
      color: theme.colors.white,
    },
  },
  h2: {
    fontSize: '1.25em',
    marginBottom: '15px',
    [`@media (min-width: ${theme.breakpoints.small}px)`]: {
      fontSize: '1.5em',
    },
    [`@media (min-width: ${theme.breakpoints.large}px)`]: {
      fontSize: '2em',
    },
  },
  button: {
    background: theme.colors.white,
    color: theme.colors.bulletnBlue.main,
    '&:hover': {
      background: theme.colors.jade.main,
      color: theme.colors.white,
      boxShadow: `0px 5px 15px ${theme.colors.bulletnBlue.main}`,
    },
  },
  email: {
    textAlign: 'center',
    color: theme.colors.white,
    background: theme.colors.bulletnBlue.dark,
    '&::placeholder': {
      color: theme.colors.white,
    },
  },
  inner: {
    [`@media (min-width: ${theme.breakpoints.small}px)`]: {
      width: '66%',
    },
  },
})

class LaunchingSoon extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Fade bottom>
          <div className={classes.inner}>
            <h2 className={classes.h2}>Join our mailing list!</h2>
            <p>
              Our team is hard at work building and testing new features every
              day, sign up to stay in the loop and hear about exciting updates!
            </p>
            <SignupForm
              align="stacked"
              emailClass={classes.email}
              buttonClass={classes.button}
            />
          </div>
        </Fade>
      </div>
    )
  }
}

export default injectSheet(styles)(LaunchingSoon)
